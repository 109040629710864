
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































.home-organizing__organizers {
  overflow: hidden;
  padding-bottom: $spacing * 2;
}

.home-organizing__organizers__title {
  line-height: 1;

  @include mq(m) {
    width: col(6, 12);
  }
}

.home-organizing__organizers__intro {
  @include mq(m) {
    width: col(5, 12);
  }
}

.home-organizing__organizers__list {
  @extend %list-nostyle;
}
