
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































$bp: xl;

.flexible-card-slider {
  ::v-deep .flexible-slider-container {
    height: 45rem;
  }

  ::v-deep .flexible-slider__controls {
    margin-top: $spacing;
  }

  @include mq($bp) {
    ::v-deep .flexible-slider-container {
      height: 54rem;
    }

    ::v-deep .flexible-slider__controls {
      margin-top: $spacing * 1.5;
    }
  }
}

.flexible-card-slider__item {
  width: 32rem;
}
